// import React, { useState, useEffect } from "react";
// import "./App.scss";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from "../components/Pages/Login";
// import Home from "../components/Pages/Home";
// import { ThemeContext, themes } from "../api/Theme";
// import { useDispatch, useSelector } from "react-redux";
// import { setPlaylist } from "../actions/actions";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

// const App = () => {
//   const [musicData, setMusicData] = useState([]);
//   const [life, setLife] = useState("");

//   const { language } = useSelector((state) => state.musicReducer);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const firebaseConfig = {
//     apiKey: "AIzaSyDSksVnUFxF5YbgzfMVLiZ7s0gsQz6K0UY",
//     authDomain: "sruthicrs-32f28.firebaseapp.com",
//     projectId: "sruthicrs-32f28",
//     storageBucket: "sruthicrs-32f28.appspot.com",
//     messagingSenderId: "951963641456",
//     appId: "1:951963641456:web:30fa177c417cafdbfd36b5",
//     measurementId: "G-2HY0H2KK8L",
//     };

//     const app = initializeApp(firebaseConfig);
//     const analytics = getAnalytics(app);
//     const db = getFirestore(app);

//     const getDataFromDB = async () => {
//       const querySnapshot = await getDocs(collection(db, "music"));
//       const dataArray = [];

//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         dataArray.push(data);
//       });

//       setMusicData(dataArray);
//     };
//     getDataFromDB();

//     return () => {
//       // Clean up function
//       setMusicData([]);
//     };
//   }, []);

//   useEffect(() => {
//     if (language === null || language.includes("any")) {
//       dispatch(setPlaylist(musicData));
//       setLife("changes");
//     } else if (language.includes("hindi")) {
//       alert("No Hindi tracks available");
//     } else {
//       setLife("changes");
//       const filteredData = musicData.filter(
//         (item) => item.lang && language.includes(item.lang.toLowerCase())
//       );
//       dispatch(setPlaylist(filteredData));
//     }
//   }, [dispatch, language, musicData]);

//   return (
//     <ThemeContext.Provider value={themes.light}>
//       <>
//         <Router>
//           <Switch>
//             <Route path="/" exact component={Login} />
//             <Route path="/home" component={Home} />
//           </Switch>
//         </Router>
//       </>
//     </ThemeContext.Provider>
//   );
// };

// export default App;

import React, {useEffect} from "react";
import './App.scss';
import Home from "../components/Pages/Home";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from "../components/Pages/Login";
import {ThemeContext, themes} from "../api/Theme";
import musicDB from "../db/music";
import {useDispatch, useSelector} from "react-redux";
import {setPlaylist} from "../actions/actions";

const App = () => {

    const {language} = useSelector(state => state.musicReducer);

    const dispatch = useDispatch();
    useEffect(()=>{
        if (language === null || language.includes("any")){
            dispatch(setPlaylist(musicDB))
        }
        else if (language.includes('hindi')){
            alert("No hindi tracks available")
        } else {
            let x = musicDB.filter((item)=>(
                item.lang && language.includes(item.lang.toLowerCase())
            ))
            dispatch(setPlaylist(x))
        }
    },[dispatch, language]);

    return (
        <ThemeContext.Provider value={themes.light}>
            <>
                <Router>
                    <Switch>
                        <Route path="/" exact component={Login}/>
                        <Route path="/home" component={Home}/>
                    </Switch>
                </Router>
            </>
        </ThemeContext.Provider>
    );
}

export default App;