import React from 'react';
import './css/About.scss';
import Container from "../fragment/Container";
// import Developer from "../fragment/Developer";
// import Attribution from "../fragment/Attribution";
import FooterSelectMusic from "../fragment/FooterSelectMusic";

const Live = () => {
    return (
        <Container>
            <div className={"About"}>
                    <div className="Dark-mode">
                        <h5>Live available soon</h5>
                        {/* <IconButton>
                            <Brightness3/>
                        </IconButton> */}
                    </div>
                {/* <Developer/>
                <Attribution/> */}
                
            </div>
        </Container>
    );
}

export default Live;
